import React, { useState } from 'react';
import { Play } from 'lucide-react';
import { PubMedArticle } from '../types';
import studyImage from '../assets/images/study.png';

interface RelatedStudiesProps {
  studies: PubMedArticle[];
}

const RelatedStudies: React.FC<RelatedStudiesProps> = ({ studies }) => {
  const [expanded, setExpanded] = useState(false);

  // Ensure studies is an array and has elements
  const safeStudies = Array.isArray(studies) ? studies : [];
  
  const displayedStudies = expanded 
    ? safeStudies
    : safeStudies.slice(0, Math.min(3, safeStudies.length));

  const showViewMoreButton = !expanded && safeStudies.length > 3;

  const handleViewMore = () => {
    setExpanded(true);
  };

  if (safeStudies.length === 0) {
    return null; // Or return a message about no studies available
  }

  return (
    <div className="mt-4 md:mt-8 md:mx-6">
      <h3 className="text-xl md:text-4xl font-bold text-[#49ABDB] mb-4 md:mb-6">VIEW REFERENCES & ACCESS SCIENCE</h3>
      <div className="flex flex-wrap -mx-2 md:-mx-4">
        {displayedStudies.map((study) => (
          <div key={study.pmid} className="w-1/2 md:w-1/4 px-2 md:px-4 mb-4 md:mb-6">
            <a href={study.url} target="_blank" rel="noopener noreferrer" className="block">
              <div className="flex flex-col items-center">
                <div className="relative w-full md:w-4/5 pb-[100%] md:pb-[80%] rounded-full overflow-hidden bg-gray-200 mb-2 md:mb-3">
                  <img
                    src={studyImage || ""}
                    alt="Study"
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <Play className="w-1/3 h-1/3 text-white" />
                  </div>
                </div>
                <p className="text-xs md:text-sm text-center text-black h-10 md:h-14 overflow-hidden">
                  {study.title}
                </p>
              </div>
            </a>
          </div>
        ))}
        {showViewMoreButton && (
          <div className="w-full md:w-1/4 px-2 md:px-4 mb-4 md:mb-6 flex items-center justify-center">
            <button 
              onClick={handleViewMore}
              className="w-full bg-blue-500 text-white px-3 md:px-4 py-2 md:py-3 rounded hover:bg-blue-600 transition-colors text-xs md:text-sm font-medium"
            >
              VIEW MORE REFERENCES
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RelatedStudies;