import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../axios';
import { Link } from 'react-router-dom';
import { Hearticle, PaginatedResponse } from '../types';
import clouds from '../assets/images/clouds.png';
import FAQ from './FAQ';
import VideoSection from './VideoSection';
import RecommendationsComponent from './RecommendationsComponent';

const HeartPort: React.FC = () => {
  const { t } = useTranslation();
  const [currentHearticles, setCurrentHearticles] = useState<Hearticle[]>([]);
  const [pastHearticles, setPastHearticles] = useState<Hearticle[]>([]);
  const [loading, setLoading] = useState(true);
  const [pastLoading, setPastLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [paginationData, setPaginationData] = useState<{
    totalPages: number;
    totalElements: number;
    first: boolean;
    last: boolean;
  }>({
    totalPages: 0,
    totalElements: 0,
    first: true,
    last: true,
  });

  useEffect(() => {
    const fetchCurrentHearticles = async () => {
      try {
        setLoading(true);
        const targetDate = new Date();
        const formattedDate = targetDate.toISOString().split('T')[0];

        const response = await axios.get<Hearticle[]>('/api/public/hearticles', {
          params: { publicationDate: formattedDate }
        });
        setCurrentHearticles(response.data);
      } catch (error) {
        console.error('Error fetching current hearticles:', error);
        setCurrentHearticles([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCurrentHearticles();
  }, []);

  useEffect(() => {
    const fetchPastHearticles = async () => {
      try {
        setPastLoading(true);
        const response = await axios.get<PaginatedResponse<Hearticle>>('/api/public/hearticles/all-past', {
          params: { page, size: 8 }
        });
        setPastHearticles(response.data.content);
        setPaginationData({
          totalPages: response.data.totalPages,
          totalElements: response.data.totalElements,
          first: response.data.first,
          last: response.data.last,
        });
      } catch (error) {
        console.error('Error fetching past hearticles:', error);
        setPastHearticles([]);
      } finally {
        setPastLoading(false);
      }
    };

    fetchPastHearticles();
  }, [page]);

  // Calculate the daily changing hue value
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 0);
  const diff = today.getTime() - startOfYear.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const dayOfYear = Math.floor(diff / oneDay);
  const hue = dayOfYear % 360;

  const getImageUrl = (topicName: string) => {
    let imageName = topicName.toLowerCase().replace(/ /g, '_') + '.png';
    if (imageName === 'recover:_immunity_&_supplements.png') imageName = 'recover_immunity_&_supplements.png';
    return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
  };
  const getFeaturedImageUrl = (topicName: string) => {
    let imageName = topicName.toLowerCase().replace(/ /g, '_') + '.png';
    if (imageName === 'recover:_immunity_&_supplements.png') imageName = 'recover_immunity_&_supplements.png';
    imageName = 'big/' + imageName;
    return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
  };

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    target.src = `${process.env.REACT_APP_API_URL}/uploads/topic_images/nutrition_medicine.png`;
    target.onerror = null; // Prevent infinite loop if default image also fails
  };

  // Background styles
  const backgroundStyle = {
    backgroundColor: 'white',
    position: 'relative' as 'relative',
    overflow: 'hidden' as 'hidden',
  };

  // Cloud image style with hue rotation
  const cloudImageStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${clouds})`,
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
    filter: `hue-rotate(${hue}deg) saturate(70%) brightness(100%)`,
    opacity: 1.0,
    zIndex: 0,
  };

  // Overlay gradient
  const overlayStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0))',
    zIndex: 1,
  };

  const renderCurrentHearticles = () => {
    if (loading || currentHearticles.length === 0) return null;

    const featuredHearticle = currentHearticles[0];
    const sideHearticles = currentHearticles.slice(1, 3);
    const gridHearticles = currentHearticles.slice(3);

    return (
      <>
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 xl:gap-[20px] mb-4 mt-16 xl:mt-48">
          {/* Featured Hearticle */}
          <div className="xl:col-span-2 bg-[#CAD4DD] p-4 xl:p-6 rounded relative overflow-visible min-h-[400px] xl:h-[600px]">
            <div className="absolute left-1/2 transform -translate-x-1/2 -top-16 xl:-top-48">
              <img
                src={getFeaturedImageUrl(featuredHearticle.topic.name)}
                alt={featuredHearticle.topic.name}
                onError={handleImageError}
                className="w-32 h-32 xl:w-96 xl:h-96 object-contain"
              />
            </div>

            <div className="mt-16 xl:mt-48">
              <div className="w-full xl:w-1/2">
                <div
                  className="px-2 py-1 rounded inline-block my-2 text-sm xl:text-base"
                  style={{
                    backgroundColor: featuredHearticle.topic.color,
                    color: '#000000',
                  }}
                >
                  {featuredHearticle.topic.name}
                </div>
                <h2 className="text-lg xl:text-2xl font-bold mb-2 text-[#111D4E]">
                  {t('heartport.todaysHearticle')}
                </h2>
                <h3 className="text-xl xl:text-3xl font-bold text-[#49ABDB] line-clamp-3">
                  {featuredHearticle.translations[0]?.headline || 'No headline available'}
                </h3>
                
                <p className="text-sm xl:text-lg text-[#111D4E] mt-2 line-clamp-2 italic">
                  {featuredHearticle.translations[0]?.keywords?.join(' - ') || 'No keywords available'}
                </p>

                <Link
                  to={`/hearticle/${featuredHearticle.id}`}
                  className="inline-block px-4 py-2 mt-4 text-base xl:text-lg text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
                >
                  {t('heartport.readFullHearticle')}
                </Link>
              </div>
            </div>
          </div>

          {/* Side Hearticles */}
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 gap-4 xl:gap-[20px]">
            {sideHearticles.map((article) => (
              <div
                key={article.id}
                className="bg-[#CAD4DD] p-4 rounded relative min-h-[200px] xl:h-[290px]"
              >
                <img
                  src={getImageUrl(article.topic.name)}
                  alt={article.topic.name}
                  onError={handleImageError}
                  className="absolute top-2 right-2 w-16 h-16 xl:w-32 xl:h-32 object-contain"
                />
                <div className="pr-20 xl:pr-32">
                  <div
                      className="px-2 py-1 rounded inline-block my-2 text-xs xl:text-base"
                      style={{
                        backgroundColor: article.topic.color,
                        color: '#000000',
                      }}
                    >
                      {article.topic.name}
                  </div>
                  <h3 className="text-base xl:text-2xl font-bold text-[#49ABDB] line-clamp-2">
                    {article.translations[0]?.headline || 'No headline available'}
                  </h3>
                  
                  <p className="text-xs xl:text-base text-[#111D4E] line-clamp-2 italic">
                    {article.translations[0]?.keywords?.join(' - ') || 'No keywords available'}
                  </p>
                  <Link
                    to={`/hearticle/${article.id}`}
                    className="inline-block px-3 py-1 mt-2 text-sm xl:text-base text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
                  >
                    {t('heartport.readFullHearticle')}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Grid Hearticles */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {gridHearticles.map((article) => (
            <div
              key={article.id}
              className="bg-[#CAD4DD] p-4 rounded relative min-h-[200px] xl:h-[280px]"
            >
              <img
                src={getImageUrl(article.topic.name)}
                alt={article.topic.name}
                onError={handleImageError}
                className="absolute top-2 right-2 w-16 h-16 xl:w-24 xl:h-24 object-contain"
              />
              <div className="pr-20">
                <div
                    className="px-2 py-1 rounded inline-block my-2 text-xs xl:text-base"
                    style={{
                      backgroundColor: article.topic.color,
                      color: '#000000',
                    }}
                  >
                    {article.topic.name}
                </div>
                <h3 className="text-base xl:text-xl font-bold text-[#49ABDB] line-clamp-2">
                  {article.translations[0]?.headline || 'No headline available'}
                </h3>
                <p className="text-xs xl:text-base text-black line-clamp-2 italic">
                  {article.translations[0]?.keywords?.join(' - ') || 'No keywords available'}
                </p>
                <Link
                  to={`/hearticle/${article.id}`}
                  className="inline-block px-3 py-1 mt-2 text-sm xl:text-base text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
                >
                  {t('heartport.readFullHearticle')}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };


  const renderPastHearticles = () => {
    if (!pastHearticles || pastHearticles.length === 0) return null;

    return (
      <div className="mt-12 lg:mt-16">
        <h2 className="text-2xl lg:text-3xl font-bold text-[#49ABDB] mb-6">Past Articles</h2>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {pastHearticles.map((article) => (
            <div
              key={article.id}
              className="bg-[#CAD4DD] p-4 rounded relative min-h-[200px]"
            >
              <img
                src={getImageUrl(article.topic.name)}
                alt={article.topic.name}
                className="absolute top-2 right-2 w-16 h-16 object-contain"
              />
              <div className="pr-20">
                <div
                    className="px-2 py-1 rounded inline-block my-2 text-xs"
                    style={{
                      backgroundColor: article.topic.color || '#49ABDB',
                      color: '#000000',
                    }}
                  >
                  {article.topic?.name || 'Uncategorized'}
                </div>
                <h3 className="text-base font-bold text-[#49ABDB] line-clamp-2">
                  {article.translations[0]?.headline || 'No headline available'}
                </h3>
                
                  
                <p className="text-xs text-black line-clamp-2 italic">
                  {article.translations[0]?.keywords?.join(' - ') || 'No keywords available'}
                </p>
                <Link
                  to={`/hearticle/${article.id}`}
                  className="inline-block px-3 py-1 mt-2 text-sm text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
                >
                  {t('heartport.readFullHearticle')}
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        {paginationData.totalPages > 1 && (
          <div className="flex justify-center items-center gap-4 mt-8">
            <button
              onClick={() => setPage(p => p - 1)}
              disabled={paginationData.first}
              className={`px-3 py-1 text-sm rounded ${
                paginationData.first
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-[#49ABDB] text-white hover:opacity-90'
              }`}
            >
              {t('heartport.previous')}
            </button>
            <span className="text-sm text-[#111D4E]">
              {t('heartport.pageOf', { current: page + 1, total: paginationData.totalPages })}
            </span>
            <button
              onClick={() => setPage(p => p + 1)}
              disabled={paginationData.last}
              className={`px-3 py-1 text-sm rounded ${
                paginationData.last
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-[#49ABDB] text-white hover:opacity-90'
              }`}
            >
              {t('heartport.next')}
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div style={backgroundStyle} className="min-h-screen mx-0 lg:mx-4 mb-24">
        <div style={cloudImageStyle}></div>
        <div style={overlayStyle}></div>
        
        <div className="relative z-10">
          <div className="p-4">
            <h1 className="text-3xl lg:text-5xl font-bold mb-4 text-[#49ABDB]">
              DEMOCRATISING SCIENCE
            </h1>
            <p className="pb-6 lg:pb-12 text-sm lg:text-lg text-[#111D4E]">
              {t('heartport.subtitle')}
            </p>

            {renderCurrentHearticles()}
            {/*renderPastHearticles()*/}

            <div className="text-center mt-8 lg:mt-16 mb-4 lg:mb-8">
              <h2 className="text-xl lg:text-3xl font-bold text-[#49ABDB]">
                {t('heartport.fightDisease')}
              </h2>
              <h3 className="text-lg lg:text-2xl font-bold text-[#49ABDB] mt-2">
                {t('heartport.tailored')}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <RecommendationsComponent
          personalizedCount={0}
          generalCount={3}
          headline="MUST READ at HEARTPORT"
          subheadline="Keep pace with what others have learned: Most read Hearticles"
        />
        
      <VideoSection />

      <FAQ />
    </div>
  );
};

export default HeartPort;
