import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Hearticle, PubMedArticle, InteractionDTO, InteractionType, SharePlatform } from '../types';
import { Play } from 'lucide-react';
import axios from '../axios';
import logo from '../assets/images/logo128.png';
import atom from '../assets/images/atom.png';
import * as Tooltip from '@radix-ui/react-tooltip';

import RelatedStudies from './RelatedStudies';
import ShareButtons from './ShareButtons';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { Button } from '../components/Button';
import DemocratisingScience from './DemocratisingScience';
import RecommendationsComponent from './RecommendationsComponent';

const FullHearticlePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [hearticle, setHearticle] = useState<Hearticle | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [readStartTime, setReadStartTime] = useState<number>(Date.now());

  const hasReviewPermission = useSelector((state: RootState) => 
    state.auth.user?.roles?.includes('PERMISSION_REVIEW_HEARTICLES') ?? false
  );

  useEffect(() => {
    const fetchHearticle = async () => {
      try {
        const response = await axios.get(`/api/public/hearticle/${id}`);
        console.log("hearticle: ", response.data)
        setHearticle(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch hearticle');
        setLoading(false);
      }
    };

    fetchHearticle();
  }, [id]);

  const recordReadInteraction = async () => {
    try {
      const interaction: InteractionDTO = {
        hearticleId: Number(id),
        interactionType: InteractionType.READ,
      };
      await axios.post('/api/interactions', interaction);
    } catch (error) {
      console.error('Failed to record read interaction:', error);
    }
  };

  useEffect(() => {
    if (id) {
      recordReadInteraction();
      setReadStartTime(Date.now());
    }
  
    return () => {
      if (id) {
        const timeSpent = Math.floor((Date.now() - readStartTime) / 1000);
        
        const interaction: InteractionDTO = {
          hearticleId: Number(id),
          interactionType: InteractionType.READ,
          timeSpentSeconds: timeSpent
        };
  
        axios.post('/api/interactions', interaction).catch(error => {
          console.error('Failed to record time spent:', error);
        });
      }
    };
  }, [id]);


  const handleRelatedArticleClick = async (pmid: string) => {
    if (id) {
      try {
        const interaction: InteractionDTO = {
          hearticleId: Number(id),
          interactionType: InteractionType.VIEW_RELATED
        };
        await axios.post('/api/interactions', interaction);
      } catch (error) {
        console.error('Failed to record related article interaction:', error);
      }
    }
  };

  const EnhancedShareButtons: React.FC<{ hearticleId: string }> = ({ hearticleId }) => {
    const handleShare = async (platform: string) => {
      // Record share interaction if user is authenticated
      try {
        const interaction: InteractionDTO = {
          hearticleId: Number(hearticleId),
          interactionType: InteractionType.SHARE,
          sharePlatform: platform as SharePlatform
        };
        await axios.post('/api/interactions', interaction);
      } catch (error) {
        console.error('Failed to record share interaction:', error);
      }

      // Original share functionality
      let url = '';
      switch (platform) {
        case 'instagram':
          navigator.clipboard.writeText(`${window.location.origin}/hearticle/${hearticleId}`);
          alert('Link copied to clipboard. You can now share it on Instagram.');
          return;
        case 'linkedin':
          url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            `${window.location.origin}/hearticle/${hearticleId}`
          )}`;
          break;
        case 'twitter':
          url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            `${window.location.origin}/hearticle/${hearticleId}`
          )}&text=${encodeURIComponent('Check out this interesting Hearticle!')}`;
          break;
        case 'email':
          url = `mailto:?subject=${encodeURIComponent(
            'Check out this interesting Hearticle!'
          )}&body=${encodeURIComponent(`${window.location.origin}/hearticle/${hearticleId}`)}`;
          break;
        case 'sms':
          url = `sms:?body=${encodeURIComponent(
            `Check out this interesting Hearticle! ${window.location.origin}/hearticle/${hearticleId}`
          )}`;
          break;
      }
      if (url) window.open(url, '_blank');
    };

    return <ShareButtons hearticleId={hearticleId} onShare={handleShare} />;
  };

  const refMap = useMemo(() => {
    if (!hearticle) return {};
    let refCounter = 1;
    const map: { [key: string]: number } = {};
    const allText = hearticle.translations[0].mainTextBody + ' ' + hearticle.translations[0].mainActions.join(' ') + ' ' + hearticle.translations[0].harmfulActions.join(' ');
    const refRegex = /\[Ref(\d+)(?:,\s*Ref(\d+))*\]/g;
    let match;
    while ((match = refRegex.exec(allText)) !== null) {
      match.slice(1).filter(Boolean).forEach(pmid => {
        if (!map[pmid]) {
          map[pmid] = refCounter++;
        }
      });
    }
    return map;
  }, [hearticle]);


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !hearticle) {
    return <div>Error: {error || 'Hearticle not found'}</div>;
  }

  const getImageUrl = (topicName: string) => {
    let imageName = topicName.toLowerCase().replace(/ /g, '_') + '.png';
    if (imageName === 'recover:_immunity_&_supplements.png') imageName = 'recover_immunity_&_supplements.png';
    imageName = 'big/' + imageName;
    return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
  };
  
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    target.src = `${process.env.REACT_APP_API_URL}/uploads/topic_images/nutrition_medicine.png`;
    target.onerror = null; // Prevent infinite loop if default image also fails
  };
  const primaryTranslation = hearticle.translations[0];

  
  const renderFormattedText = (text: string) => {
    const lines = text.split('\n');
    return lines.map((line, lineIndex) => {
      const elements: React.ReactNode[] = [];
      let lastIndex = 0;
      const pattern = /(\*\*(.*?)\*\*)|(\*(.*?)\*)|(__(.*?)__)|(_(.*?)_)|(\[Ref\d+(?:,\s*Ref\d+)*\])|(\{(.*?)\}\s*)\[EXP:(.*?)\]/g;
      let match;
  
      while ((match = pattern.exec(line)) !== null) {
        // Add text before the match
        if (match.index > lastIndex) {
          elements.push(line.substring(lastIndex, match.index));
        }
  
        // Process the match
        if (match[1]) {
          // **bold**
          elements.push(
            <strong key={`${lineIndex}-${match.index}-bold`}>
              {match[2]}
            </strong>
          );
        } else if (match[3]) {
          // *italic*
          elements.push(
            <em key={`${lineIndex}-${match.index}-italic`}>
              {match[4]}
            </em>
          );
        } else if (match[5]) {
          // __underline__
          elements.push(
            <u key={`${lineIndex}-${match.index}-underline`}>
              {match[6]}
            </u>
          );
        } else if (match[7]) {
          // _italic_
          elements.push(
            <em key={`${lineIndex}-${match.index}-italic-underscore`}>
              {match[8]}
            </em>
          );
        } else if (match[9]) {
          // [Ref...]
          const refPart = match[9];
          const refs = refPart
            .slice(1, -1)
            .split(',')
            .map((ref) => ref.trim().slice(3));
          elements.push(
            <sup key={`${lineIndex}-${match.index}-ref`}>
              {refs.map((pmid, index) => {
                const article = hearticle?.relatedPubMedArticles.find(
                  (a) => a.pmid === pmid
                );
                return (
                  <React.Fragment key={index}>
                    {index > 0 && ','}
                    <span
                      className="text-blue-500 cursor-pointer text-xs align-super ml-0.5"
                      title={article ? article.title : 'Article not found'}
                      onClick={() => {
                        if (article && article.url) {
                          window.open(article.url, '_blank');
                        }
                      }}
                    >
                      [{refMap[pmid]}]
                    </span>
                  </React.Fragment>
                );
              })}
            </sup>
          );
        } else if (match[10]) {
          // {words}[EXP:...]
          const words = match[11];
          const explanation = match[12];
          elements.push(
            <span
              key={`${lineIndex}-${match.index}-exp`}
              className="text-blue-500 cursor-pointer relative group"
              onClick={(e) => {
                const tooltip = e.currentTarget.querySelector('.tooltip');
                if (tooltip) {
                  tooltip.classList.toggle('visible');
                }
              }}
            >
              {words}
              <span className="tooltip invisible group-hover:visible absolute bottom-full left-1/2 transform -translate-x-1/2 px-3 py-1.5 bg-black text-white text-sm mb-2 z-50 max-w-[280px] whitespace-normal break-words md:max-w-[24rem] lg:break-normal lg:w-max">
                {explanation.trim()}
                <span className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-black rotate-45"></span>
              </span>
            </span>
          );
        
        }
  
        lastIndex = pattern.lastIndex;
      }
  
      // Add any remaining text after the last match
      if (lastIndex < line.length) {
        elements.push(line.substring(lastIndex));
      }
  
      return (
        <React.Fragment key={lineIndex}>
          {elements}
          {lineIndex < lines.length - 1 && <br />}
        </React.Fragment>
      );
    });
  };
  
    

  return (
    <div>
      <div className="mx-auto md:p-4 mx-0 lg:mx-4 font-sans bg-white">
        {/* Header */}
        <header className="flex justify-between p-4 md:p-0 items-center mb-4">
          <div className="flex items-center">
            <img src={logo || ''} alt="HeartPort logo" className="mr-2 w-8 h-8 md:w-auto md:h-auto" />
            <h1 className="text-xl md:text-2xl font-bold text-[#49ABDB]">HEARTPORT</h1>
          </div>
        </header>

        {/* Main Title and Subtext */}
        <h2 className="text-4xl md:text-8xl font-bold text-[#49ABDB] p-4 mb-4 md:p-0">DEMOCRATISING SCIENCE</h2>
        <p className="text-base md:text-lg text-black mb-4 p-4 md:p-0 ">
          Key health information backed by accessible scientific research to maximize your physical and mental self.
        </p>

        {/* Image */}
        <div className="w-full flex justify-center relative z-10">
          <img
            src={getImageUrl(hearticle.topic.name)}
            onError={handleImageError}
            alt="Topic Image"
            className="w-72 h-72 xl:w-[36rem] xl:h-[36rem]"
          />
        </div>

        {/* div overlapping image */}
        <div className="bg-[#90C4D9] p-4 relative mt-[-9rem] md:mt-[-18rem] z-0 sm:mt-4">
          <div className="absolute top-4 left-4 md:mx-6 md:pt-0 pt-36">
            <DemocratisingScience />
          </div>
          {/* Spacer to adjust for the image */}
          <div className="h-72 md:h-64"></div>

          {/* Three columns */}
          <div className="flex flex-col md:flex-row md:mx-6">
            {/* Left third */}
            <div className="w-full md:w-1/3 mb-4 md:mb-0 mt-4 sm:mt-6"> {/* Add top margin for mobile */}
              <h4 className="text-xl md:text-3xl font-bold text-black mb-2">YOUR BREAKING HEARTICLE:</h4>
              <div
                className={`px-2 py-1 rounded inline-block text-black mb-2`}
                style={{ backgroundColor: hearticle.topic.color}}
              >
                {hearticle.topic.name}
              </div>
              <h5 className="text-4xl md:text-4xl font-bold text-[#49ABDB] mb-2">{primaryTranslation.headline}</h5>
              
              <p className="mb-2 text-2xl font-semibold text-black italic">
                {primaryTranslation?.keywords?.join(' - ') || 'No keywords available'}
              </p>
              
              {/* Now we use the pre-computed permission */}
              {hasReviewPermission && (
                <Button
                  onClick={() => navigate(`/review/translation/${primaryTranslation.id}`)}
                  variant="primary"
                >
                  Review Translation
                </Button>
              )}
            </div>

            {/* Middle third */}
            <div className="w-full md:w-1/3 flex justify-center mb-4 md:mb-0">
              <button className="flex items-center justify-center bg-blue-500 text-white w-16 h-16 rounded-full md:mt-16">
                <Play size={24} />
              </button>
            </div>

            {/* Right third */}
            <div className="w-full md:w-1/3 flex flex-col items-center">
              <p className="mb-2 text-2xl md:text-3xl text-white font-bold">SHARE HEARTICLE</p>
              <EnhancedShareButtons hearticleId={id || ''} />
            </div>
          </div>

          {/* HEALTH ESSENTIALS heading */}
          <h3 className="text-2xl font-bold text-[#49ABDB] mb-2 mt-8 md:mx-6">HEALTH ESSENTIALS</h3>

          {/* Main body and action feed */}
          <div className="flex flex-col md:flex-row">
            {/* Main body */}
            <div className="w-full md:w-2/3 pr-0 md:pr-4 mb-4 md:mb-0 md:mx-6">
              <p className="text-l text-justify text-black mb-4 whitespace-pre-wrap">
                {renderFormattedText(primaryTranslation.mainTextBody)}
              </p>
            </div>

            {/* Action feed */}
            <div className="w-full md:w-1/3 md:mx-4">
              <div className="bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] p-4">
                <div className="mb-4">
                  <h3 className="text-4xl font-bold mb-6 mt-4 text-white">ACTION FEED</h3>
                  <br />
                  
                  {/* Main Actions - only show if there are items */}
                  {primaryTranslation.mainActions.length > 0 && (
                    <>
                      <h4 className="text-2xl font-bold mb-6 text-white italic">das hilft</h4>
                      <ul className="list-disc list-inside text-white text-xl">
                        {primaryTranslation.mainActions.map((action, index) => (
                          <li key={index} className="whitespace-pre-wrap mb-4 font-semibold">
                            {renderFormattedText(action)}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  {/* Centered Atom Image - only show if either list has items */}
                  {(primaryTranslation.mainActions.length > 0 || primaryTranslation.harmfulActions.length > 0) && (
                    <div className="flex justify-center my-8">
                      <img src={atom} alt="Atom" className="w-16 h-16" />
                    </div>
                  )}

                  {/* Harmful Actions - only show if there are items */}
                  {primaryTranslation.harmfulActions.length > 0 && (
                    <>
                      <h4 className="text-2xl font-bold mb-6 text-white italic">das schadet</h4>
                      <ul className="list-disc list-inside text-white text-xl">
                        {primaryTranslation.harmfulActions.map((action, index) => (
                          <li key={index} className="whitespace-pre-wrap mb-4 font-semibold">
                            {renderFormattedText(action)}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Related Studies at the end */}
          <RelatedStudies studies={hearticle.relatedPubMedArticles} />

          
        </div>
      </div>
      <RecommendationsComponent
            personalizedCount={4}
            generalCount={3}
            headline="Your Personalized Recommendations"
            subheadline="Based on your reading history"
          />
    </div>
  );
};

export default FullHearticlePage;