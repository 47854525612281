import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { SubscriptionLevel, SubscriptionStatus } from '../store/authSlice';
import clouds from '../assets/images/clouds.png';
import HearticleStatistics from './HearticleStatistics';
import UserManagement from './UserManagement';

const Profile = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [activeTab, setActiveTab] = useState('profile');

  // Calculate the daily changing hue value
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 0);
  const diff = today.getTime() - startOfYear.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const dayOfYear = Math.floor(diff / oneDay);
  const hue = dayOfYear % 360;

  const backgroundStyle = {
    backgroundColor: 'white',
    position: 'relative' as 'relative',
    overflow: 'hidden' as 'hidden',
  };

  const cloudImageStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${clouds})`,
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
    filter: `hue-rotate(${hue}deg) saturate(70%) brightness(100%)`,
    opacity: 1.0,
    zIndex: 0,
  };

  const overlayStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0))',
    zIndex: 1,
  };
  const formatPermissionName = (permission: string): string => {
    return permission
      .replace('PERMISSION_', '')
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  const formatRoleName = (roleName: string): string => {
    return roleName.replace('ROLE_', '').toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatSubscriptionLevel = (level: SubscriptionLevel): string => {
    return level.charAt(0).toUpperCase() + level.slice(1).toLowerCase();
  };

  const getSubscriptionStatusColor = (status: SubscriptionStatus): string => {
    switch (status) {
      case 'ACTIVE':
        return 'bg-green-500';
      case 'INACTIVE':
        return 'bg-gray-500';
      case 'PENDING':
        return 'bg-yellow-500';
      case 'CANCELLED':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };


  return (
    <div style={backgroundStyle} className="min-h-screen">
      <div style={cloudImageStyle}></div>
      <div style={overlayStyle}></div>

      <div className="relative z-10 container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-[#49ABDB] mb-8">Profile Settings</h1>

          <div className="bg-[#CAD4DD] rounded-lg shadow-lg p-6">
            <div className="flex border-b border-gray-300 mb-6">
              <button
                className={`px-4 py-2 font-bold ${
                  activeTab === 'profile'
                    ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                    : 'text-[#111D4E]'
                }`}
                onClick={() => setActiveTab('profile')}
              >
                Profile Information
              </button>
              <button
                className={`px-4 py-2 font-bold ${
                  activeTab === 'subscription'
                    ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                    : 'text-[#111D4E]'
                }`}
                onClick={() => setActiveTab('subscription')}
              >
                Subscription
              </button>
              {user?.roles?.includes('PERMISSION_VIEW_STATISTICS') && (
                <button
                  className={`px-4 py-2 font-bold ${
                    activeTab === 'statistics'
                      ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                      : 'text-[#111D4E]'
                  }`}
                  onClick={() => setActiveTab('statistics')}
                >
                  Statistics
                </button>
              )}
              {user?.roles?.includes('PERMISSION_ADMIN') && (
                <button
                  className={`px-4 py-2 font-bold ${
                    activeTab === 'users'
                      ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                      : 'text-[#111D4E]'
                  }`}
                  onClick={() => setActiveTab('users')}
                >
                  Users
                </button>
              )}
            </div>

            {activeTab === 'profile' && (
              <div className="space-y-6">
                <div>
                  <h2 className="text-xl font-bold text-[#111D4E] mb-4">Account Details</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-[#111D4E] mb-1">
                        Username
                      </label>
                      <p className="text-lg font-medium">{user?.username}</p>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-[#111D4E] mb-1">
                        Email
                      </label>
                      <p className="text-lg font-medium">{user?.email}</p>
                    </div>
                  </div>
                </div>
                
                <div>
                    <h2 className="text-xl font-bold text-[#111D4E] mb-4">Permissions</h2>
                    <div className="flex flex-wrap gap-2">
                        {user?.roles?.map((permission, index) => (
                        <span
                            key={index}
                            className="px-3 py-1 bg-[#49ABDB] text-white rounded-full text-sm"
                        >
                            {formatPermissionName(permission)}
                        </span>
                        ))}
                    </div>
                    </div>

                <div className="space-y-4">
                  <h2 className="text-xl font-bold text-[#111D4E]">Security</h2>
                  <button
                    className="px-4 py-2 bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] text-white font-bold rounded hover:opacity-90 transition-opacity"
                  >
                    Change Password
                  </button>
                </div>
              </div>
            )}

            {activeTab === 'subscription' && (
              <div>
                <h2 className="text-xl font-bold text-[#111D4E] mb-4">Your Subscription</h2>
                <div className="bg-white rounded-lg p-6 space-y-4">
                  <div>
                    <p className="text-sm text-[#111D4E] mb-2">Current Plan</p>
                    <div className="inline-block px-4 py-2 bg-[#49ABDB] text-white rounded-lg text-base font-medium">
                      {user?.subscriptionLevel ? formatSubscriptionLevel(user.subscriptionLevel) : 'Free'}
                    </div>
                  </div>

                  <div>
                    <p className="text-sm text-[#111D4E] mb-2">Status</p>
                    <div className={`inline-block px-3 py-1 text-white rounded-full text-sm ${
                      user?.subscriptionStatus ? 
                      getSubscriptionStatusColor(user.subscriptionStatus) : 
                      'bg-gray-500'
                    }`}>
                      {user?.subscriptionStatus || 'INACTIVE'}
                    </div>
                  </div>

                  <div className="pt-4 border-t border-gray-200">
                    <button
                      className="px-4 py-2 bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] text-white font-bold rounded hover:opacity-90 transition-opacity"
                      onClick={() => {
                        // Add upgrade subscription handler
                        console.log('Upgrade subscription clicked');
                      }}
                    >
                      Upgrade Plan
                    </button>
                  </div>

                  <p className="text-sm text-gray-600 mt-4">
                    Manage your subscription and billing information. Premium features coming soon.
                  </p>
                </div>
              </div>
            )}
            {activeTab === 'statistics' && user?.roles?.includes('PERMISSION_ADMIN') && (
              <HearticleStatistics />
            )}
            {activeTab === 'users' && user?.roles?.includes('PERMISSION_ADMIN') && (
              <UserManagement />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;