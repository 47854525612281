import React from 'react';
import insta from '../assets/images/insta_btn.png';
import linkedin from '../assets/images/linkedin_btn.png';
import x from '../assets/images/x_btn.png';
import mail from '../assets/images/mail_btn.png';
import phone from '../assets/images/phone_btn.png';

interface ShareButtonsProps {
  hearticleId: string;
  onShare: (platform: string) => void;
}

const ShareButtons: React.FC<ShareButtonsProps> = ({ hearticleId, onShare }) => {
  // Remove the handleShare function and use onShare prop instead
  return (
    <div className="flex space-x-2">
      <button onClick={() => onShare('instagram')} className="w-12 h-12 overflow-hidden">
        <img src={insta} alt="Share on Instagram" className="w-full h-full object-cover" />
      </button>
      <button onClick={() => onShare('linkedin')} className="w-12 h-12 overflow-hidden">
        <img src={linkedin} alt="Share on LinkedIn" className="w-full h-full object-cover" />
      </button>
      <button onClick={() => onShare('twitter')} className="w-12 h-12 overflow-hidden">
        <img src={x} alt="Share on Twitter" className="w-full h-full object-cover" />
      </button>
      <button onClick={() => onShare('email')} className="w-12 h-12 overflow-hidden">
        <img src={mail} alt="Share via Email" className="w-full h-full object-cover" />
      </button>
      <button onClick={() => onShare('sms')} className="w-12 h-12 overflow-hidden">
        <img src={phone} alt="Share via SMS" className="w-full h-full object-cover" />
      </button>
    </div>
  );
};

export default ShareButtons;